import axiosLib, { AxiosInstance } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Providers } from '@tripian/model';
import { handleHttpResponseErrorForViator } from './handle';

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  // private apiUrl = `https://api.viator.com/partner`;
  // private apiUrl_TEST = `https://api.sandbox.viator.com/partner/`;
  // private apiKey = 'ecb944f4-396a-42ec-a89c-3e59ef671465';
  // private apiKey_TEST = '87f21f6f-6cb4-4ba3-ac0b-b763edc67165';

  private sandbox: boolean;

  constructor(apiUrl: string, apiKey: string, sandbox: boolean, proxyURL: string) {
    this.proxyURL = proxyURL;
    this.sandbox = sandbox;
    this.apiUrl = apiUrl;
    this.axios = axiosLib.create();
    this.axios.defaults.baseURL = `${this.proxyURL}?url=${apiUrl}`;
    this.axios.defaults.headers.common['exp-api-key'] = apiKey;
    // this.axios.defaults.headers.common.accept = 'application/json;version=2.0';
    this.axios.defaults.timeout = 30000; // 2500;
  }

  private getDestinationId = async (cityName: string): Promise<string> => {
    const url = btoa(`${this.apiUrl}/search/freetext`);

    return this.axios
      .post<any>(
        `${this.proxyURL}?url=${url}`,
        {
          searchTerm: cityName,
          searchTypes: [
            {
              searchType: 'DESTINATIONS',
              pagination: {
                start: 1,
                count: 10,
              },
            },
          ],
          currency: 'USD',
        },
        {
          headers: {
            accept: 'application/json;version=2.0',
          },
        },
      )
      .then((res) => {
        let destinationId = '';
        const totalCount = res.data.destinations.totalCount as number;
        if (totalCount > 0) {
          destinationId = res.data.destinations.results[0].id;
        }
        return destinationId;
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  products = async (cityName: string, startDate: string, endDate: string, limit = 30): Promise<Providers.Viator.Product[]> => {
    const url = btoa(`${this.apiUrl}/products/search`);

    const destination = await this.getDestinationId(cityName);
    if (destination === '') return [];

    return this.axios
      .post<{ products: Providers.Viator.Product[] }>(
        `${this.proxyURL}?url=${url}`,
        {
          filtering: {
            destination, // '562',
            tags: [21972],
            flags: ['LIKELY_TO_SELL_OUT', 'FREE_CANCELLATION'],
            lowestPrice: 5,
            highestPrice: 500,
            startDate, // '2023-05-30',
            endDate, // '2023-06-28',
            includeAutomaticTranslations: true,
            confirmationType: 'INSTANT',
            durationInMinutes: {
              from: 20,
              to: 360,
            },
            rating: {
              from: 3,
              to: 5,
            },
          },
          sorting: {
            sort: 'TRAVELER_RATING',
            order: 'DESCENDING',
          },
          pagination: {
            start: 1,
            count: limit,
          },
          currency: 'USD',
        },
        {
          headers: {
            accept: 'application/json;version=2.0',
          },
        },
      )
      .then((res) => {
        return res.data.products ?? [];
      })
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };

  productInfo = async (productCode: string): Promise<Providers.Viator.ProductInfo> => {
    const url = btoa(`${this.apiUrl}/products/${productCode}`);

    return this.axios
      .get<Providers.Viator.ProductInfo>(`${this.proxyURL}?url=${url}`, {
        headers: {
          accept: 'application/json;version=2.0',
        },
      })
      .then((res) => res.data)
      .catch((errorResponse) => handleHttpResponseErrorForViator(errorResponse));
  };
}

export default API;
